import React from 'react';

import SectionBlock from '../components/Block/SectionBlock';

const sections = [
  {
    img: false,
    text: [
      {
        title: 'Customer',
        description:
          '<p>WTS - Positioning Solutions AB is a Swedish company founded in 2012 with the goal to develop ' +
          'world-leading GPS services and high precision trackers. Since its foundation, the company has developed ' +
          'and launched six generations of tracking devices and services on the global market.</p>',
      },
      {
        title: 'Challenge',
        description:
          '<ul>' +
          '<li>Transition to 4G that required new GPS trackers hardware and firmware</li>' +
          '<li>Enhancing new business opportunities by adding new features and functionality</li>' +
          '<li>Scaling up development capabilities to meet customers expectations</li>' +
          '</ul>',
      },
      {
        title: 'Solution',
        description:
          '<ul>' +
          '<li>SolveQ project team able to focus on every aspect of the development</li>' +
          '<li>Architecture design services of backend</li>' +
          '<li>React Native based mobile application development on Android and IOS</li>' +
          '<li>Infrastructure as a Code based on Google Cloud Platform enabling maintainability and serviceability</li>' +
          '</ul>',
      },
      {
        title: 'Results ',
        description:
          '<ul>' +
          '<li>The basis for business growth opportunities through white labeling</li>' +
          '<li>New potential use cases like kids tracking, pets tracking</li>' +
          '<li>New mobile application enhanced functionality:' +
          '<ul>' +
          '<li>tighter feedback/integration loop</li>' +
          '<li>in-app and cross-channel benefits</li>' +
          '<li>ability to interact with the user even the app is in background</li>' +
          '</ul>' +
          '</li>' +
          '</ul>',
      },
      {
        title: 'Technologies ',
        description:
          '<ul>' +
          '<li>Infrastructure:' +
          '<ul>' +
          '<li>Google Kubernetes Engine on Google Cloud Platform</li>' +
          '<li>Terraform - infrastructure as code</li>' +
          '</ul></li>' +
          '<li>Application client:' +
          '<ul>' +
          '<li>React Native (iOS, Android)</li>' +
          '</ul></li>' +
          '</ul>',
      },
    ],
  },
];

const JohanPage = () => {
  return (
    <>
      <div className="th-hero-page">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="th-hero-content">
                <h1 className="th-hero-content__header">GPS Tracking Application</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <SectionBlock imgFull imgFirst {...sections[0]} />
    </>
  );
};

export default JohanPage;
